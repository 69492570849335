<template>
  <div>
    <div class="wrapper" @click="redirectToDetailPageChild(database)">
      <video
        v-if="playerActive && database && database.sources && !isMobile"

        :id="'player_carousel_' + isActive"
        :src="database.sources"
        :poster="database.thumb"
        class="top-carousel-class"
        
        muted
        @ended="handleVideoEnded"
      ></video>
      <div v-else style="height: fit-content; ">
        <img class="carousel-poster-img" :src="database.thumb ? database.thumb : placeholder_lands" />
      </div>

      <div class="hero-meta-description">
        <div class="title" v-if="database.title">
          <p> {{ database.title }}</p>
        </div>
        <div class="main-info">
          <div class="ua-rating" v-if="database.advisory">
            <p>{{ database.advisory }}</p>
          </div>
          <div class="publish-year" v-if="database.productionyear">
            <p>{{ database.productionyear }}</p>
          </div>
          <div class="duration" v-if="database.duration">
            <p>{{ database.duration }}</p>
          </div>
          <div class="pgrating" v-if="database.pgrating">
            <p>{{ database.pgrating }}</p>
          </div>
        </div>
        <div class="sub-desc" v-if="database.description">
          <Description :toggle="false" :descStyle="descStyle" :whereIAmFrom="'futureCarousel'" :content="database"/>
        </div>
        <div class="main-genre">
          <div class="sub-genre1" v-if="database.category">
            <p>{{$t(database.category)}}</p>
          </div>
          <div class="sub-genre2" v-if="database.genre">
            <p>{{$t(database.genre)}}</p>
          </div>
          <div class="sub-genre3" v-if="database.contentlanguage">
            <p>{{$t(database.contentlanguage)}}</p>
          </div>
        </div>
        <div class="more-details">
          <p style="display: flex; justify-content:center">
            <PlayButton :whereIamFrom="'featured'" :content="database" :playerInstance="playerInstance"></PlayButton>
          </p>
          <p class="more-info"  @click="redirectToDetailPageChild(database)">
            <InfoButton :whereIamFrom="'featured'" :content="database"></InfoButton>
          </p>
          <CspaceWatchlistBtn :content="database"  class="changeSvg addtoWatchlist" :isSmall="true" />
        </div>
      </div>

      <div class="mute-unmute-btn" @click="ChangeMute"  v-if="playerActive && database.sources  && !isMobile">
          <!-- <img :src="ismuted ? Mute : Unmute" alt="" /> -->
          <VolumeIcon :status="ismuted ? 'mute' : 'unmute'" :dimensions="volumeIconDimensions"/>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import Redirection from '@/mixins/redirection.js';

export default {
  props: ["database", "isActive", "ended", "playerInstance"],
  data() {
    return {
      playerActive: true,
      ismuted: true,
      Mute: require('@/assets/icons/mute.png'),
      Unmute: require('@/assets/icons/unmute.png'),
      isMobile: false,
      intervalTimeout: null,
      showMore: true,
      moreDesc: false,
      description: '',
      descStyle: {
        font: 1,
        width: 42
      },
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
      volumeIconDimensions: {width:'36', height:'36'}
    };
  },
  created() {
    eventBus.$on('stopCarousel', (data) => {
      if (this.intervalTimeout) {
          clearInterval(this.intervalTimeout);
        }
    });
    eventBus.$on(`event-callback-parentallock-${this.database.objectid}-featured`, (data) => {
      alert('hello world');
    })
  },
  mounted() {
    this.isMobile = window.innerWidth <= 1200 ?  true :  false;
  
    eventBus.$on('event-isMuted', () => {
      this.ismuted = true
    })

    this.initCarousel();
    this.spliceText(this.database.description, 170);
  },
  watch: {
    isActive () {
      this.initCarousel();
    },
    database () {
      this.spliceText(this.database.description, 170);
    }
  },
  components: {
      CspaceWatchlistBtn: () =>
      import(
        /* webpackChunkName: "watchlisbutton" */ "@/components/SvgImages/CspaceWatchlistBtn.vue"
      ),
      PlayButton: () => import(/* webpackChunkName: "playButton" */ "@/components/buttons/play.vue"),
      InfoButton: () => import(/* webpackChunkName: "playButton" */ "@/components/SvgImages/Info.vue"),
      VolumeIcon: () => import(/* webpackChunkName: "volumeIcon" */ "@/components/SvgImages/Volume.vue"),
      Description: () => import (/* webpackChunkName: "description" */ "@/components/Text/Description.vue")
  },
  methods: {
    redirectToDetailPageChild (data) {
      console.error("----")
      this.$emit("redirectToDetailPageChildCB", data);
    },
    handleVideoEnded () {
      this.$emit("ended", true);
    },
    initCarousel () {
      setTimeout(() => {
        if (this.intervalTimeout) {
          clearInterval(this.intervalTimeout)
        }
        if (!(this.database.sources && !this.isMobile)) {
          this.intervalTimeout = setTimeout(() => {
            this.$emit("ended", true);
          }, 5000);
        }
      }, 1000)
    },
    actMoreDetails () {
      this.$router.push({name: 'detailPageEarth', params:{
        title: this.database.defaulttitle ,
        contentId: this.database.objectid
      }})
    },
    spliceText(text, maxLength) {
      if (text && text.length > maxLength) {
        this.moreDesc = true;
        // return text?.slice(0, maxLength) + '...'; 
        this.description = text?.slice(0, maxLength) + '...'; 
      } else {
        // return text; 
        this.description = text + '.' ; 
      }
    },
    ChangeMute() {
      this.ismuted = !this.ismuted;
      const video = document.getElementById("player_carousel_" + this.isActive);
      video.muted = !video.muted;
    },
    moreless(text, maxLength) {
      this.showMore = !this.showMore;
      if(this.showMore){
        this.description = text?.slice(0, maxLength) + '...';
      } else {
        this.description = text + '.';
      }
    }
  },
  mixins: [Utility, Redirection]
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.wrapper {
  background: black;
  width: 100%;
  z-index: 1;
  aspect-ratio: 2/1;
  overflow: hidden;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: var(--clr-carousel-image-shadow-two);
  }
  video {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  .carousel-poster-img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
  }
}

.hero-meta-description {
  position: absolute;
  top: 15rem;
  z-index: 2;
  left: 3.2rem;
  .title {
    line-height: 1.5rem;
    font-weight: 700;
    font-size: 1.7rem !important;
    text-transform: uppercase;
  }
}
.wrapper-slider-section {
  position: relative;
  margin-top: -6rem;
  z-index: 5;
  color: white;
}
.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}
.main-info {
  display: flex;
  gap: 1rem;
}
.main-genre {
  display: flex;
  gap: 1rem;
}
.hero-meta-description > div:not(:first-child) {
  margin-top: 0.7rem;
}
.main-info {
  font-size: 1rem;
}
.main-genre {
  font-size: 1rem;
  color: #ffffff;
}
.sub-desc {
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
  p {
    width: 35rem;
    .moreDesc {
      font-size: 0.6rem;
      border: #3b3a3a 0.8px solid;
      padding: 0.1rem 0.4rem;
      border-radius: 0.2rem;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.main-genre .sub-genre2 p::before,
.main-genre .sub-genre3 p::before {
  content: "• ";
  position: relative;
  right: 0.4rem;
}

.ua-rating {
  background: #32373e;
  border-radius: 0.2rem;
  color: #ffffff;
}
.ua-rating,
.publish-year,
.duration {
  padding: 0.2rem;
}
.publish-year,
.duration {
  color: #aaaaaa;
}
.pgrating {
  border: #3b3a3a 0.8px solid;
  padding: 0.1rem 0.4rem;
  border-radius: 0.2rem;
  color: #ffffff;
}
.title {
  color: #ffffff;
  font-weight: 600;
  
  font-size: 2.5rem;
}

.more-details{
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.more-info {
  background: transparentize($color: #3b3a3a, $amount: 0.5);
  width: 9rem;
  transition: background 0.3s ease-in;
}
.more-info:hover {
  // background: transparentize($color: #b00101, $amount: 0.2);
  background: #3b3a3a;
  transform: scale(1.1);
  box-shadow: 0px 0px 4px 1px rgba(31, 31, 31, 0.425);
}
.play-button{
  background: $primary-btn-color;
  width: 6rem;
  // @include hoverEffectStye1('', '',#f800007f, '');
  &:hover {
    transform: translate(-5px);
    transform: scale(0.9);
  }
}


.more-info, .play-button {
  color: #FFFFFF;
  // padding: 0.4rem;
  border-radius: 0.2rem;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  margin-top: 0rem;
  cursor: pointer;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // @include hoverEffectStye1('', 0.3s ease-in-out,'', scale(1.1));
}
.addtoWatchlist{
  margin-top: 0rem;
  transform: scale(1.1);
}
.mute-unmute-btn {
  position: absolute;
  right: 4%;
  top: 45%;
  z-index: 555;
  cursor: pointer;
  img {
    width: 40px !important;
  }
  // @include hoverEffectStye1('', 0.3s ease-in-out,'', scale(1.2))
}


@media only screen and (max-width: 600px) {
  
  .wrapper {
     margin-top: 4rem;
  }

 }

@media only screen and (max-width: 1023px) {
   .hero-meta-description{
    display: none;
   }
   .wrapper {
      cursor: pointer;
      position: relative;
      aspect-ratio: 16 / 9;
   }
  }
  @media only screen and (max-width: 1200px) {
    .hero-meta-description{
      top: 7rem;
    }
  }

 

</style>